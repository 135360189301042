import * as actionTypes from '../../actions/auth/actionTypes';

export const initialState = {
    accessToken: "",
    refreshToken: ""
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_AUTH_DATA:
            return {
                ...state,
                accessToken: action.data.tokens.access.token,
                refreshToken: action.data.tokens.refresh.token
            };
        case actionTypes.SET_USER_INFO: {
            return {
                ...state,
                user: (action?.data?.user ?? {}),
                userBusiness: (action?.data?.userBusiness ?? {}),
            }
        }
        default:
            return state;
    }
};

export default authReducer;
