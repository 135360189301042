import { requestWithToken, simpleRequest } from '../apiConfigs/request';

export const registerCandidate = (userData) => {
    return simpleRequest({
        url: '/auth/candidate-register',
        method: 'POST',
        data: userData
    });
}

export const loginCandidate = (userCred) => {
    return simpleRequest({
        url: '/auth/login',
        method: 'POST',
        data: userCred
    });
}

export const fetchUserInfo = () => {
    return requestWithToken({
        url: '/auth/me',
        method: "GET"
    })
}

export const logOutUserService = ()=> {
    return requestWithToken({
        url: '/auth/logout',
        method: "POST",
        data: {
            refreshToken: localStorage.getItem("refreshToken") || ""
        }
    })
}

export const callRefresh = () => {
    return simpleRequest({
        url: '/auth/refresh-tokens',
        method: "POST",
        data: {
            refreshToken: localStorage.getItem("refreshToken") || ""
        }
    })
}

export const createCandidateByAdmin = (data) => {
    return requestWithToken({
      url: `/auth/admin-candidate-register`,
      method: 'POST',
      data,
    });
}

export const createUserInterest = (data) => {
    return requestWithToken({
      url: `/user-interest`,
      method: 'POST',
      data,
    });
}

export const loginWithVerifyToke = (token) => {
    return simpleRequest({
        url: `/auth/verify-email`,
        method: 'POST',
        data: { token },
    });
};

export const registerEmployer = (data) => {
    return simpleRequest({
        url: `/auth/employer-signup`,
        method: 'POST',
        data: data,
    });
};

const AuthService = {
    callRefresh,
}
export default AuthService;
