import {requestWithToken} from "../apiConfigs/request";

export const fetchBusinessList = (query) => {
  return requestWithToken({
    url: `/business${query}`,
    method: 'GET'
  });
}

export const createBusiness = (data) => {
  return requestWithToken({
    url: '/business',
    method: 'POST',
    data,
  });
}

export const fetchBusinessById = (businessId) => {
  return requestWithToken({
      url: `/business/${businessId}`,
      method: 'GET',
  });
}

export const updateBusiness = (businessId, data) => {
  return requestWithToken({
    url: `/business/${businessId}`,
    method: 'PATCH',
    data,
  });
}
