// material-ui
import { useState } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from '../MenuItems';
import { useSelector } from 'react-redux';
import _ from 'lodash';


export const allowedRoutes = {
  candidate: {
    '/job': true,
    '/job/:jobId': true,
    '/my-opportunity': true,
    '/my-opportunity/:jobId': true,
  },
  business: {
    '/companySetup':true,
    '/job': true,
    '/job/:jobId': true,
    'job/edit/:jobId':true,
    '/settings/business/create': true,
    '/settings/business/:businessId': true
  },
};
const MenuList = () => {
    const [aiModel, setAiModel] = useState(localStorage.getItem('aiModel') ? localStorage.getItem('aiModel') : 'GPT');
    const {userType} = useSelector((state) => {
        return {
            userType: state?.auth?.user?.userType
        }
    });

    const navItems = menuItem.items.map((item) => {
        let updatedItem = _.cloneDeep(item)
        updatedItem.children  = updatedItem.children.filter(({url}) => {
            return allowedRoutes?.[userType]?.[url] || userType === "admin";
        });
        switch (updatedItem.type) {
            case 'group':
                return <NavGroup key={updatedItem.id} item={updatedItem} />;
            default:
                return (
                    <Typography key={updatedItem.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>
        {navItems}
      {userType === 'admin' ?
        <div className="flex justify-start">
          <Box mt={2} ml={4}>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>Select AI Model</FormLabel>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                value={aiModel}
                name="radio-buttons-group"
                onChange={(event) => {
                    setAiModel(event?.target?.value)
                    localStorage.setItem('aiModel', event?.target?.value)
                }}
              >
                <FormControlLabel value='GPT' control={<Radio />} label='GPT' />
                <FormControlLabel value="CLAUDE" control={<Radio />} label="Claude" />
              </RadioGroup>
            </FormControl>
          </Box>
        </div>
        :
        null
      }
    </>;
};

export default MenuList;
